import { StatusCode } from "@assets/index"
import { configApi } from "@common/api"
import axios from "axios"
import { setNotification } from "../reducers"

const DOMAIN = import.meta.env.VITE_APP_HOSTNAME

// TODO referralId поставил по умолчанию, пока на беке его не сделают необязательным, это ошибка бека

export const authSocialAction = ({ social }) => async dispatch => {
  try {
    const { data } = await axios.create(configApi).get(`/users/social/o/${social}/?redirect_uri=${DOMAIN}auth/login/`)

    window.location.href = data.authorizationUrl
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }))
    // dispatch(authFailureAction((error as any).message))
  }
}

export const fetchSocialAction = ({ code, state, social }) => async dispatch => {
  try {
    const params = new URLSearchParams({ code, state })
    const config = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }

    const { data } = await axios.create(configApi).post(`/users/social/o/${social}/`, params, config)

    localStorage.setItem("session", data.access)
    localStorage.setItem("refresh", data.refresh)

    // dispatch(authedActionSuccess(data.access))
  } catch (error) {
    const message =
      (error as any).response.status === StatusCode.SERVER_ERROR
        ? "Данный способ авторизации невозможен. Попробуйте использовать другие учётные данные"
        : (error as any).message
    dispatch(setNotification({ type: "error", message: message }))
    // dispatch(authFailureAction((error as any).message))
  }
}
