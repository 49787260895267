import { FormFooter, ModalContainer } from "@common/components/ModalComponents";
import { useAsyncDispatch, useTypedSelector } from "@common/hooks";
import { Field, Form as FinalForm } from "react-final-form";
import { FormInputText, InputFloat, Selector, StyledMenuItem } from "../../../../../common/components/ui";
import { Form, MB24 } from "./styles";
import { ReactElement, useEffect } from "react";
import { getAccountsTypesThunk } from "@common/services";

type Props = {
  open: boolean;
  close: () => void;
  onSubmit?: (values: CreateBillModalValues) => void;
  onCancel?: () => void;
};

export interface CreateBillModalValues {
  type: string;
  available: string;
  name: string;
}

export function CreateBillModal({ open, close, onCancel, onSubmit }: Props): ReactElement {
  const types = useTypedSelector(state => state.accounts.types);
  const asyncDispatch = useAsyncDispatch();

  useEffect(() => {
    asyncDispatch(getAccountsTypesThunk);
  }, []);

  return (
    <ModalContainer title="Создание счёта" width={400} open={open} onClose={close}>
      <FinalForm<CreateBillModalValues>
        onSubmit={values => onSubmit?.(values)}
        render={({ handleSubmit, submitting, valid, pristine }) => {
          const disabled = submitting || !valid || pristine;

          return (
            <Form onSubmit={handleSubmit}>
              <MB24>
                <Field<string> name="type" validate={value => !value && "Required"}>
                  {({ input }) => (
                    <Selector
                      input={input}
                      label="Тип счёта"
                      placeholder="Введите тип счёта"
                      list={types}
                      renderValue={value => (
                        <StyledMenuItem value={value.id} key={value.alias}>
                          {value.name}
                        </StyledMenuItem>
                      )}
                    />
                  )}
                </Field>
              </MB24>
              <MB24>
                <Field<string> name="name" validate={value => !value && "Required"}>
                  {({ input }) => (
                    <FormInputText input={input} label="Наименование" placeholder="Введите наименование" />
                  )}
                </Field>
              </MB24>
              <MB24>
                <Field<string> name="available" validate={value => !value && "Required"}>
                  {({ input }) => <InputFloat input={input} label="Текущий баланс" placeholder="Введите баланс" />}
                </Field>
              </MB24>
              <FormFooter disabled={disabled} onRightButtonClick={onCancel} />
            </Form>
          );
        }}
      />
    </ModalContainer>
  );
}
