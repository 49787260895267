import { ComponentType, ReactNode } from "react";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import { DropdownIndicatorProps } from "react-select/dist/declarations/src/components/indicators";
import {
  OptionLabel,
  OptionWrapper,
  SelectContainer,
  SelectLabel,
  StyledChevronDownIcon,
  StyledReactSelect,
} from "./styles";
import { GroupBase } from "./types";
import { components } from "react-select";
import styled from "styled-components";

export const reactSelectPrefixClassName = "react_select";
export const reactSelectWrapperClassName = "wrapper";

export interface DefaultSelectOption {
  label: string;
  value: string;
}

type Props<Option, Group extends GroupBase<Option>> = {
  input: FieldInputProps<Option>;
  options: readonly Option[];
  groups?: readonly Group[]
  placeholder?: string;
  label?: string;
  meta?: FieldMetaState<Option>;
  optionRender?: (data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => ReactNode;
  groupLabelRender?: (group: GroupBase<Option>) => ReactNode;
  formatCreateLabel?: (inputValue: string) => ReactNode;
  required?: boolean;
  maxLength?: number;
  renderCustomOption?: (option: Option, selectOption: (newValue: Option) => void) => ReactNode
  UpperComponent?: ReactNode;
};

export function ReactSelect<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: Props<Option, Group>,
) {
  const {
    input,
    optionRender,
    placeholder,
    options,
    groupLabelRender,
    label,
    required,
    meta,
    maxLength,
    UpperComponent,
    renderCustomOption,
  } = props;

  return (
    <SelectContainer>
      {label !== undefined && <SelectLabel $required={required}>{label}</SelectLabel>}
      <StyledReactSelect<string | ComponentType<any>>
        id={input.name}
        value={input.value}
        name={input.name}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        placeholder={placeholder}
        formatOptionLabel={optionRender}
        formatGroupLabel={groupLabelRender}
        options={options}
        className={reactSelectWrapperClassName}
        classNamePrefix={reactSelectPrefixClassName}
        components={{
          DropdownIndicator: DropdownIndicator,
          ...(UpperComponent !== undefined) && ({
            MenuList: (props) => {
              const search: string | undefined = props?.selectProps?.inputValue;
              const filteredOptions = filterElementsBySearch(options, search)

              return (
                <MenuWithUpperComponent
                  UpperComponent={UpperComponent}
                  options={filteredOptions}
                  selectOption={props.selectOption}
                  renderCustomOption={renderCustomOption}
                />
              );
            },
          }),
          Input: (props) => <components.Input {...props} maxLength={maxLength} />,
        }}
        $error={(meta?.invalid && meta?.touched) || (meta?.invalid && meta?.submitting)}
      />
    </SelectContainer>
  );
}

const DropdownIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  _: DropdownIndicatorProps<Option, IsMulti, Group>,
) => <StyledChevronDownIcon />;

export function formatOption<Option extends DefaultSelectOption>(
  data: Option,
  formatOptionLabelMeta: FormatOptionLabelMeta<Option>,
) {
  const { label } = data;
  const { context } = formatOptionLabelMeta;

  if (context === "value") {
    return <OptionLabel>{label}</OptionLabel>;
  }

  return (
    <OptionWrapper>
      <OptionLabel>{label}</OptionLabel>
    </OptionWrapper>
  );
}

type MenuWithUpperComponentProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> = {
  UpperComponent: ReactNode
  options: readonly Option[]
  selectOption: (newValue: Option) => void;
  renderCustomOption?: (option: Option, selectOption: (newValue: Option) => void) => ReactNode
}

function MenuWithUpperComponent<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(props: MenuWithUpperComponentProps<Option, IsMulti, Group>) {
  const {
    UpperComponent,
    options,
    selectOption,
    renderCustomOption,
  } = props;

  return (
    <SelectedContainer>
      {UpperComponent}
      {options.map(option => renderCustomOption?.(option, selectOption))}
    </SelectedContainer>
  );
}

const SelectedContainer = styled.div`
  overflow: auto;
  max-height: 350px;
`;


function filterElementsBySearch(data, search) {
  return data.map(item => {
    const filteredOptions = item?.options?.filter(option => option.label?.toLowerCase()?.includes(search?.toLowerCase() || ""));
    return {
      label: item.label,
      options: filteredOptions,
    };
  }).filter(item => item.options.length > 0);
}
