import axios from "axios";

import { StatusCode } from "../../../assets";

export const savingerUrl = import.meta.env.VITE_APP_URI

export type APIResponse<T> = {
  response: {
    data: T
  }
}

export const configApi = {
  timeout: 15000,
  baseURL: savingerUrl,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
}

export const configApiFormData = {
  timeout: 15000,
  baseURL: savingerUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
  },
}

export const API = axios.create(configApi)
export const APIFormData = axios.create(configApiFormData)

API.interceptors.request.use(config => {
  const token = localStorage.getItem("session")
  config.headers.Authorization = token ? `JWT ${localStorage.getItem("session")}` : null

  return config
})

API.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === StatusCode.FORBIDDEN) {
      localStorage.clear()
      window.location.href = "/auth"
    }

    return Promise.reject(error)
  },
)
